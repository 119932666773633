import { none, State, Immutable } from "@hookstate/core"
import { Validation, ValidationError } from "@hookstate/validation"
import branding, { Branding } from "../branding/branding"
import {
    EventCloudProduct,
    EventCloudProductKey
} from "../branding/configurations/global/utilityConfigs/EventCloudProductsConfiguration"
import { ErrorMessagesConfiguration } from "../branding/configurations/global/validation/ErrorMessagesConfiguration"
import { ValidationMessagesConfiguration } from "../branding/configurations/global/validation/ValidationMessagesConfiguration"
import { isNumeric } from "../utils"
import { Person } from "./ExhibitorDataTypes"

type Identifiable = {
    id: string
}

export function addElement<T>(elementStates: State<T[]>, newElement: T) {
    const elementIndex = elementStates.get().findIndex((element) => (element as any)?.id === newElement)

    if (elementIndex >= 0) {
        elementStates[elementIndex].set(newElement)
        return
    }

    if (elementStates.get().length === 0) {
        elementStates.set([newElement])
        return
    }

    elementStates.merge([newElement])
}

export function removeElementById<T extends Identifiable>(elementStates: State<T[]>, elementId: string) {
    const elementIndex = elementStates.get().findIndex((element) => element.id === elementId)
    if (elementIndex >= 0) {
        elementStates[elementIndex].set(none)
    }
}

export function getElementFieldValue<T>(elementState: State<T, Validation>, fieldName: keyof State<T, Validation>) {
    return elementState[fieldName].get() ?? undefined
}

export function getElementLocalizedName(element: any, language: string, property: string) {
    return language === "en" ? element[property] : element.localized[language][property]
}

export function makeSetHandler<T>(elementState: State<T, Validation>, fieldName: keyof State<T, Validation>) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        elementState[fieldName].set(event.target.value)
    }
}

export function makeCheckHandler<T>(elementState: State<T, Validation>, fieldName: keyof State<T, Validation>) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        elementState[fieldName].set(event.target.checked)
    }
}

export function makeSetHandlerPlain<T>(elementState: State<T, Validation>, fieldName: keyof State<T, Validation>) {
    return (value: unknown) => {
        elementState[fieldName].set(value)
    }
}

export function makeValidationHandler<T>(elementState: State<T, Validation>, fieldName: keyof State<T, Validation>) {
    // return () => {
    //     if (!elementState[fieldName]) {
    //         console.log("ValidationHandler - missing field from state", elementState, fieldName, elementState[fieldName], elementState.promised)
    //     }
    //     return elementState[fieldName].errors()
    // }
    return () =>
        elementState &&
        elementState[fieldName as keyof State<T, Validation>] &&
        elementState[fieldName as keyof State<T, Validation>].errors()
}

export function getEntityCardTitle(entity: any, lang: string): string | null {
    if (entity.title.get()) {
        if (lang === "De") {
            return entity.titleDe.get() || entity.title.get()
        }

        return entity.title.get() || entity.titleDe.get()
    }

    if (lang === "De") {
        return entity.nameDe.get() || entity.name.get()
    }

    return entity.name.get() || entity.nameDe.get()
}

export function hasError(lang: string, validationError: ValidationError) {
    const fieldName = validationError.path ? (validationError.path[validationError.path.length - 1] as string) : ""

    if (isNumeric(fieldName)) return true

    if (lang === "En") {
        return !fieldName.endsWith("De")
    }
    return fieldName.endsWith(lang)
}

export function hasErrors(lang: string, entityState?: State<any, Validation>) {
    if (entityState) {
        const validationErrors = entityState.errors()
        // console.log(validationErrors)
        return validationErrors.some((validationError) => hasError(lang, validationError))
    }
    return false
}

export function hasErrorsAll(entityState?: State<any, Validation>) {
    return hasErrors("En", entityState) || hasErrors("De", entityState)
}

export function translateValidationErrors(errors: readonly ValidationError[], branding: Immutable<Branding>): string {
    const messageKey = errors && errors.length > 0 ? errors[0].message : ""
    const errorMessage = branding.configuration.validationMessages[messageKey as keyof ValidationMessagesConfiguration]
    return errorMessage || messageKey
}

export function translateErrors(error: string, branding: Immutable<Branding>): string {
    const messageKey = error || ""
    const errorMessage = branding.configuration.errorMessages[messageKey as keyof ErrorMessagesConfiguration]
    return errorMessage || messageKey
}

export function truncateWithEllipsis(str: string, maxLength: number) {
    return str.length > maxLength ? str.substring(0, maxLength) + "..." : str
}

export function capitalizeFirstCharacter(str: string) {
    if (typeof str !== "string" || str.length === 0) {
        return str
    }

    return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1)
}

export function isContentLanguageAvailable(language: string): boolean {
    return branding.contentLanguages.indexOf(language) > -1
}

export function getDefaultContentLanguage(): string {
    const contentLanguages = branding.contentLanguages

    return contentLanguages.length > 0 ? capitalizeFirstCharacter(contentLanguages[0]) : "En"
}

export function getCountText(selectionNumber: number, limit: number) {
    return branding.lblLimit.replace("{$selected}", String(selectionNumber)).replace("{$total}", String(limit))
}

export function getPersonFullName(person: State<Person, Validation>): string {
    const fullName = person.firstName.get() && person.lastName.get() ? `${person.firstName.get()} ${person.lastName.get()}` : ""

    return fullName
}

export function getFileExtension(file: File): string | null {
    // Extract the file name
    const fileName = file.name

    // Find the last dot in the file name
    const lastDotIndex = fileName.lastIndexOf(".")

    // If there is no dot, return null
    if (lastDotIndex === -1 || lastDotIndex === 0) {
        return null
    }

    // Extract and return the file extension
    return fileName.substring(lastDotIndex + 1).toLowerCase()
}

export function getFileNameWithoutExtension(file: File): string {
    // Extract the file name
    const fileName = file.name

    // Find the last dot in the file name
    const lastDotIndex = fileName.lastIndexOf(".")

    // If there is no dot or dot is at the start, return the original name (no extension)
    if (lastDotIndex === -1 || lastDotIndex === 0) {
        return fileName
    }

    // Extract and return the file name without the extension
    return fileName.substring(0, lastDotIndex)
}

export function getFileExtensionForName(fileName: string | undefined): string | null {
    if(fileName === undefined) return null
    
    // Find the last dot in the file name
    const lastDotIndex = fileName.lastIndexOf(".")

    // If there is no dot or the dot is at the start, return null
    if (lastDotIndex === -1 || lastDotIndex === 0) {
        return null
    }

    // Extract and return the file extension
    return fileName.substring(lastDotIndex + 1).toLowerCase()
}

export function getDefaultEventCloudProduct(): EventCloudProduct {
    return branding.configuration.eventCloudProducts.list.length > 0
        ? branding.configuration.eventCloudProducts.list[0]
        : EventCloudProduct.EVENT_GUIDE
}

export function getEventCloudProductKey(selectedProduct: EventCloudProduct): EventCloudProductKey {
    switch (selectedProduct) {
        case EventCloudProduct.EVENT_GUIDE:
            return "EventGuide"
        case EventCloudProduct.MOBILE_GUIDE:
            return "Mobile"
        case EventCloudProduct.WEB_WIDGETS:
            return "Widget"
    }
}
