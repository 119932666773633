import { useLanguageState } from "../globalStates/LanguageState"

interface LocalizedStaticTexts {
    de: StaticTexts
    en: StaticTexts
    [key: string]: StaticTexts
}

export interface StaticTexts {
    cancel: string
    ok: string
    confirm: string
    download: string
    save: string
}

const staticTexts: LocalizedStaticTexts = {
    en: {
        cancel: "Cancel",
        confirm: "Confirm",
        ok: "OK",
        download: "Download",
        save: "Save"
    },
    de: {
        cancel: "Abbrechen",
        confirm: "Confirm",
        ok: "OK",
        download: "Herunterladen",
        save: "Speichern"
    }
}

function useStaticTexts(): StaticTexts {
    const lang = useLanguageState().getLanguage()

    return staticTexts[lang]
}

export default useStaticTexts
